<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Estefania Villada Chavarria                                   ###### -->
<!-- ###### @date: Abril 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <div class="contenido">
        <div v-if="url === ''" class="pa-4">
            <!-- Descripción del módulo -->
            <div class="d-flex align-center mb-3">
                <v-icon color="blue darken-4" size="55">analytics</v-icon>
                <span class="ms-4 descripcion">
                    Herramienta diseñada para la visualización de los diferentes informes por procesos de Power BI.<br>
                    Por favor, utiliza los filtros para encontrar informes relevantes para tu proceso de interés.
                </span>
            </div>
            <v-divider color="#9E9E9" class="mb-5"></v-divider>

            <!-- Filtros -->
            <div class="d-flex mt-3">
                <v-select class="filtro" v-model="proceso" dense outlined hide-details label="Proceso" no-data-text="No se encontraron resultados."
                    :items="obtenerProcesos" :menu-props="{ offsetY: true}" @change="listarTableros">
                </v-select>
                
                <v-autocomplete v-model="tablero" class="ms-2 filtro" dense outlined hide-details label="Tableros" :items="opciones.tableros"
                    :menu-props="{ offsetY: true}" @change="seleccionarTablero" :no-data-text="proceso !== '' && proceso !== null  ? 'No se encontraron resultados.' : 'Primero filtra por proceso.'">
                </v-autocomplete>
            </div>
        </div>

        <!-- Tablero -->
        <iframe v-else :title="titulo" width="100%" height="98%" :src="url" frameborder="0" allowFullScreen="true" ></iframe>
        
        <!-- Botón para salir del tablero -->
        <v-btn v-if="url !== ''" icon class="volver-btn" @click="clear()"><v-icon color="primary" >logout</v-icon></v-btn>
    </div>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { mapState } from "vuex";
import { Role } from "../../../../router/role.js";

export default {
    name: 'InformesBI',
    data() {
        return {
            userRoles: {},
            roles: Role,
            opciones: {
                procesos: [
                    { text: 'Gestión Administrativa', value: 'A', role: 'Analitica_Administrativo'},
                    { text: 'Gestión Comercial', value: 'C', role: 'Analitica_Comercial'},
                    { text: 'Gestión del Talento Humano', value: 'T', role: 'Analitica_TalentoHumano'},
                    { text: 'Gestión Farmacéutica', value: 'S', role: 'Analitica_ServiciosFarmaceuticos'},
                    { text: 'Gestión Financiera', value: 'F', role: 'Analitica_Facturacion'},
                    { text: 'Negociación, Compras y Abastecimiento', value: 'N', role: 'Analitica_Compras'},
                    { text: 'Operación Logística', value: 'L', role: 'Analitica_Logistica'},
                    { text: 'Prestación de Servicios de Salud', value: 'P', role: 'Analitica_ServiciosSalud'},
                ],
                tableros: [],
            },
            proceso: '',
            tablero: '',
            url: '',
            titulo: '',
        }
    },
    computed: {
        ...mapState(["auth", "notify", "busy", "enterprise"]),
        /**
         * Filtrar los procesos disponibles basadas en los roles del usuario.
         */
        obtenerProcesos() {
            const rolesUsuario = this.auth.roles;
            return this.opciones.procesos.filter(proceso => rolesUsuario.includes(proceso.role));
        },
    },
    methods: {
        /**
         * Lista los tableros disponibles según el proceso seleccionado.
         */
        listarTableros() {
            this.opciones.tableros = [];
            this.tablero = '';
            if (this.proceso) {
                this.$http.get(`msa-reports/parametrizacionTablero/listarTableros`, {
                    params: {
                        idEmpresa: this.enterprise.code,
                        proceso: this.proceso
                    }
                }).then((response) => {
                    response.data.forEach((item) => {
                        this.opciones.tableros.push({ text: `${item.tituloTablero}`});
                    })
                }).catch((error) => {
                    console.error('Error al listar tableros:', error);
                })
            }
        },
        /**
         * Trae la información del tablero seleccionado.
         */
        seleccionarTablero() {
            this.$http.get(`msa-reports/parametrizacionTablero/traerTablero`, {
                params: {
                    idEmpresa: this.enterprise.code,
                    tituloTablero: this.tablero
                }
            }).then((response) => {
                const respuesta = response.data[0];
                this.url = respuesta.urlTablero;
                this.titulo = respuesta.tituloTablero;
            }).catch((error) => {
                console.error('Error al traer la información del tablero:', error);
            })
        },
        /**
         * Restablece las variables relacionadas con la selección de tableros a su estado inicial.
         */
        clear() {
            this.tablero = '';
            this.url = '';
            this.titulo = '';
        },
    },
    mounted() {
        const rolesUsuario = this.auth.roles;
        const primerProceso = this.opciones.procesos.find(proceso => rolesUsuario.includes(proceso.role));
        if (primerProceso) {
            this.proceso = primerProceso.value;
            this.listarTableros();
        }
        window.addEventListener('load', () => {
            const iframe = document.querySelector('.iframe');
            iframe.contentWindow.addEventListener('scroll', (event) => {event.preventDefault()},
            { passive: false });
        });
    },
}
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de styles                                                      ###### -->
<!-- #################################################################################### -->
<style scoped>
.contenido {
    width: 100%;
    height: 85vh;
}
.iframe {
    border: solid #0000006b 0.5px;
    border-radius: 4px;
}
.filtro {
    width: 50%;
}
.volver-btn {
  position: absolute;
  top: 50px;
  right: 20px;
}
.descripcion {
    color: rgb(64, 62, 62); 
}
</style>